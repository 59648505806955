export function handleActiveFilterClick(event) {
  // Get the form element name
  const name = $(event.target).closest('span').data('name');
  if(!name) {
    throw Error('"name" is not defined.');
  }
  // Clear the form element value
  let $elements = this.$form.find(`[name="${name}"]`);
  let $radioElements = $elements.filter('input[type="radio"]');
  let $checkboxElements = $elements.filter('input[type="checkbox"]');

  switch ($elements.length) {
    case 0:
      throw Error('Element not found!')
    case 1:
      if($checkboxElements.length) {
        $checkboxElements[0].click(); // Clear checkboxes by click on them.
      } else {
        $elements.val(null); // Clear others by settting their value to null.
      }
      break;
    default:
      if($radioElements.length) {
        // Clear radio groups by clicking on the option with blank value
        let $blanklOption = $radioElements.filter('input[value=""]');
        if($blanklOption.length == 1) {
          $blanklOption[0].click();
        } else {
          throw Error(`Unexpected number of blank options found: ${$blanklOption.length}`);
        }
      } else {
        $elements.val(null); // Clear others by settting their value to null.
      }
      break;
  }

  this.onlyFacetsChanged = false; // This flag (when false) is used to refresh the right side filters.
  this.submitFilters();
}
